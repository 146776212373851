<template>
    <div>
        <header class="dosiin_header-topic-detail" style="display: unset;">
            <div class="back-header dosiin_back-header-sticky">
                <div class="go-back-icon" @click="goBack()">
                    <i class="dsi dsi-chevron-left"></i>
                    <a class="text">Quay lại</a>
                </div>
                <h2 class="text-grey-lg center">#{{this.tag}}
                    <div class="sub-title-header" style="display:block;"> {{ numberFormat(post_count)}} bài viết</div>
                </h2>
                <div class="back-header-right-side">
                    <div class="toggle-view-buttons">
                        <!-- <span :class="['dosiin_grid-icon-style grid-icon', isActiveGrip]" @click="style='normal'"></span> -->
                        <span :class="['dosiin_list-icon-style list-icon' , isActiveGripSpecial]" @click="style='special'"></span>
                    </div>
                </div>
            </div>
        </header>
        <main class="dosiin_home home-page main-page dosiin_topic-detail">
            <PostsLoader 
                v-if="style == 'special'" 
                ref="postLoader" 
                :params="{'sort_by':'random' , 'tag' : tag , 'timestamp' : new Date().getTime()}"
                @total-post="updateTotals"
            />
            <section v-else class="topic-detail-list dosiin_post_view">
                <ul class="dosiin_view grid grid-33">
                    <li class="post-group grid-item" v-for="(post , key ) in postLoader" :key="key">
                        <router-link :to="{ name :'detail' , query:{vnfd_id : post.vnfd_id}}">
                            <div class="img-wrapper">
                                <div class="dosiin-tag-feed">
                                    <a class="d-flex align-items-center justify-content-between" href="#" title="Hastag">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18832 2.78866C9.47476 2.49609 9.86421 2.33203 10.2692 2.33203H13.4159C14.1014 2.33203 14.6662 2.90816 14.6666 3.60728V6.81755C14.6666 7.23076 14.5054 7.6274 14.219 7.91956L9.5866 12.6447C8.99336 13.2499 8.01858 13.2499 7.42533 12.6447L7.3944 12.6132L6.80837 13.2115C6.21512 13.8166 5.24035 13.8166 4.6471 13.2115L1.77825 10.2846C1.185 9.67951 1.185 8.68575 1.77825 8.08062L6.41008 3.35544C6.69653 3.06287 7.08598 2.89881 7.49099 2.89881H9.08034L9.18832 2.78866ZM8.24687 3.74898H7.49099C7.30646 3.74898 7.13021 3.82346 6.99992 3.95654L2.36754 8.68172C2.09286 8.9619 2.09286 9.40337 2.36754 9.68354L5.23693 12.6099C5.51161 12.89 5.94441 12.89 6.21908 12.6099L6.80511 12.0121L4.55648 9.71786C3.96323 9.11273 3.96323 8.11898 4.55648 7.51385L8.24687 3.74898ZM12.1657 4.03237C11.9446 4.03237 11.7326 4.12194 11.5763 4.28138C11.42 4.44081 11.3322 4.65706 11.3322 4.88253C11.3322 5.10801 11.42 5.32426 11.5763 5.48369C11.7326 5.64313 11.9446 5.7327 12.1657 5.7327C12.3867 5.7327 12.5987 5.64313 12.755 5.48369C12.9113 5.32426 12.9992 5.10801 12.9992 4.88253C12.9992 4.65706 12.9113 4.44081 12.755 4.28138C12.5987 4.12194 12.3867 4.03237 12.1657 4.03237Z" fill="#E6E6E6"></path>
                                        </svg>
                                        <span class="dosiin_ml-1" v-text="Object.keys(post.categories_brands_with_name).length"></span>
                                    </a>
                                </div>
                                <div class="dosiin-layer">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.75001 2.33208C3.68744 2.33119 3.62531 2.34275 3.56724 2.36609C3.50917 2.38942 3.45632 2.42406 3.41176 2.468C3.36719 2.51194 3.33181 2.5643 3.30765 2.62203C3.2835 2.67976 3.27106 2.74172 3.27106 2.8043C3.27106 2.86688 3.2835 2.92884 3.30765 2.98657C3.33181 3.0443 3.36719 3.09666 3.41176 3.1406C3.45632 3.18453 3.50917 3.21918 3.56724 3.24251C3.62531 3.26584 3.68744 3.2774 3.75001 3.27652H12.25C12.3126 3.2774 12.3747 3.26584 12.4328 3.24251C12.4908 3.21918 12.5437 3.18453 12.5883 3.1406C12.6328 3.09666 12.6682 3.0443 12.6924 2.98657C12.7165 2.92884 12.729 2.86688 12.729 2.8043C12.729 2.74172 12.7165 2.67976 12.6924 2.62203C12.6682 2.5643 12.6328 2.51194 12.5883 2.468C12.5437 2.42406 12.4908 2.38942 12.4328 2.36609C12.3747 2.34275 12.3126 2.33119 12.25 2.33208H3.75001ZM3.27779 4.22096C3.21521 4.22007 3.15309 4.23164 3.09502 4.25497C3.03695 4.2783 2.9841 4.31295 2.93953 4.35688C2.89497 4.40082 2.85958 4.45318 2.83543 4.51091C2.81128 4.56864 2.79884 4.6306 2.79884 4.69318C2.79884 4.75576 2.81128 4.81772 2.83543 4.87545C2.85958 4.93318 2.89497 4.98554 2.93953 5.02948C2.9841 5.07341 3.03695 5.10806 3.09502 5.13139C3.15309 5.15473 3.21521 5.16629 3.27779 5.1654H12.7222C12.7848 5.16629 12.8469 5.15473 12.905 5.13139C12.9631 5.10806 13.0159 5.07341 13.0605 5.02948C13.1051 4.98554 13.1404 4.93318 13.1646 4.87545C13.1887 4.81772 13.2012 4.75576 13.2012 4.69318C13.2012 4.6306 13.1887 4.56864 13.1646 4.51091C13.1404 4.45318 13.1051 4.40082 13.0605 4.35688C13.0159 4.31295 12.9631 4.2783 12.905 4.25497C12.8469 4.23164 12.7848 4.22007 12.7222 4.22096H3.27779ZM3.27779 6.10984C2.75598 6.10984 2.33334 6.53248 2.33334 7.05428V12.7209C2.33334 13.2427 2.75598 13.6654 3.27779 13.6654H12.7222C13.244 13.6654 13.6667 13.2427 13.6667 12.7209V7.05428C13.6667 6.53248 13.244 6.10984 12.7222 6.10984H3.27779Z" fill="#E6E6E6"></path>
                                    </svg>
                                    <span class="dosiin_ml-1">1</span>
                                </div>
                                <img v-lazy="_postImage(post.main_pair)" class="load_scroll cm-image pict" style="width:100%" height="107">
                            </div>
                            <div class="desc">
                                <div class="name" v-text="post.user_name"></div>
                                <div class="time"> <span>{{ _momentFromNow(post.timestamp) }} </span></div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </section>
        </main>
    </div>
</template>

<script>
    import PostsLoader from '@/components/post/PostLoader.vue';
    import { mapState , mapMutations} from 'vuex';
    export default {
        components:{
            PostsLoader
        },
        data(){
            return {
                style : 'special',
                tag: this.$route.query.tag,
                post_count: 0,
            }
        },
        watch:{
            '$route.query.tag': {
                handler: function(tag) {
                    this.tag = tag;
                },
                //deep: true,
                immediate: true
            },
            tag(tag){
                this.$refs.postLoader.reset({'sort_by':'random' , 'tag' : tag , page : 1});
            }
        },
        created(){
            this.DELETE_POST_LOADER();
        },
        computed:{
            ...mapState('PostsStore',['postLoader']),
            isActiveGrip(){
                if(this.style == 'normal') return 'active';
            },
            isActiveGripSpecial(){
                if(this.style == 'special') return 'active';
            }
        },
        methods:{
            ...mapMutations('PostsStore',[
                'DELETE_POST_LOADER'
            ]),
            updateTotals(totals){
                this.post_count = totals;
            }
        }
    }
</script>