<template>
    <div class="dosiin-user">
        <div class="search_brand-item" v-for="i in 5" :key="i">
            <div class="search_brand-right">
                <Skeletor circle size="62" />
                <div class="search_brand-txt dosiin_ml-2">
                    <h4 class="name truncate" style="width: 200px;">
                        <Skeletor width="70%" />
                    </h4>
                    <Skeletor width="100%" />
                </div>
            </div>
        </div>
    </div>
</template>

