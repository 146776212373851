<template>
    <main class="dosiin_home home-page main-page styleMainPage">
        <div class="box-wrapper-pc dosiin_padding-header-top dosiin_pd-top-24">
            <div class="box-grid-pc" :key="tag">
                <div class="box-grid-pc_left">
                    <div class="dosiin-categories-topic-detail">
                        <div class="dosiin-categories-topic">
                            <div class="header-post">
                                <div class="detail-avatar">
                                    <div class="name-detail">#{{this.tag}}</div>
                                </div>
                            </div>
                            <div class="view-all">
                                <span class="view">Hiển thị</span>
                                <a class="categories-list" @click="changeGrid('normal')">
                                    <i :class="['dsi' , style == 'normal' ? 'dsi-act-fill-grid' : 'dsi-fill-grid']"></i>
                                </a>
                                <a class="categories-list" @click="changeGrid('special')">
                                    <i :class="['dsi' , style == 'special' ? 'dsi-act-fill-radiolist' : 'dsi-fill-radiolist']"></i>
                                </a>
                            </div>
                        </div>

                        <div class="dosiin-line-left"></div>

                        <PostsLoader 
                            :style="style"
                            ref="postLoader" 
                            :params="{'sort_by':'random' , 'tag' : tag , 'timestamp' : new Date().getTime()}"
                            @total-post="updateTotals"
                        />
 
                    </div>
                </div>

                <div class="box-grid-pc_right">
                    <div class="style-box-wrapper">
                        <div class="title_style">
                            <h4>Thông tin chủ đề</h4>
                        </div>
                        <div class="section-detail-post">
                            <div class="detail-post dosiin_mr-2">
                                <div class="quantily">
                                    <div class="quantily-txt">
                                        <h4 v-text="numberFormat(post_count)"></h4>
                                        <p>Bài viết</p>
                                    </div>
                                </div>
                            </div>
                            <div class="detail-post">
                                <div class="quantily">
                                    <div class="quantily-txt">
                                        <h4 v-text="numberFormat(user_count)"></h4>
                                        <p>Người viết</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="share-btn">
                            <button class="button primary-button confirm-btn">
                                <i class="dsi dsi-act-arrow-forward"></i>
                                <span class="primary-link_text">Chia sẻ phong cách theo chủ đề</span>
                            </button>
                        </div> -->
                    </div>    

                    <div class="style-box-wrapper dosiin_mb-16 dosiin_mt-16">
                        <div class="title_style">
                            <h4>Người dùng nổi bật</h4>
                            <!-- <div class="title_style-more">
                                <span>Xem thêm </span><i class="dsi dsi-act-arrow-forward"></i>
                            </div> -->
                        </div>

                        <SkeletonQuickUser v-if="loadingUser" />
                        <div class="dosiin-user" v-else>
                            <div class="search_brand-item" v-for="(user , index) in users" :key="index">
                                <div class="search_brand-right">
                                    <router-link class="search_style-img" :to="{name : 'profile' , query: {profile_id : user.user_id }}">
                                        <img 
                                            v-lazy="{ src: _userAvatar(user.avatar), loading: _userDefaultAvatar, error: _userDefaultAvatar}"
                                            width="62" 
                                            height="62" 
                                            :alt="user.firstname" 
                                        />
                                    </router-link>
                                    <div class="search_brand-txt">
                                        <h4 class="name truncate" style="width: 115px;">
                                            <router-link :to="{name : 'profile' , query: {profile_id : user.user_id }}">
                                                {{user.firstname}}
                                            </router-link> 
                                        </h4>
                                        <div class="search_brand-follow">{{user.follow_count}}<span>người theo dõi</span></div>
                                    </div>
                                </div>
                                <div class="search_style-left">
                                    <FollowButton 
                                        v-model="user.follow_count"
                                        @update:modelValue="newValue => user.follow_count = newValue"
                                        followable_type="user" 
                                        :followable_id="user.user_id" 
                                        :is_follow="user.is_follow"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <GroupTags />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import PostsLoader from '@/components/post/PostLoader.vue';
    import GroupTags from '@/components/hashtag/GroupTags.vue';
    import { ref , computed , watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex'
    import UserService from '@/services/user';
    import { serialize } from '@/utlis/serialize';
    import { _dsEmptyObject } from '@/utlis/helpers';
    import SkeletonQuickUser from '@/components/skeletons/UserQuickSuggest';
    export default {

        setup(){
            const route = useRoute();
            const store = useStore()
            const style = ref('special');
            const tag = ref(route.query.tag);
            const post_count = ref(0);
            const user_count = ref(0);
            const users      = ref([]);
            getUsers();
            const loadingUser = ref(true);

            watch(() => route.query,
                async query => {
                    if(_dsEmptyObject(query) || typeof query.tag == 'undefined') {
                        return;
                    }
                    tag.value = query.tag;
                }
            );


            async function getUsers(){
                var params = {
                    'items_per_page' : '5',
                    'user_type'      : 'V',
                    'status'         : 'A',
                    'sort_by'        : 'random',
                    'tag'            : tag.value,
                    'get_vnfd'       : true 
                };

                const response = await UserService.fetchUsers(serialize(params));
                
                if(response.data){
                    users.value.push(...response.data.users);
                    user_count.value = response.data.params.total_items;
                    loadingUser.value = false;
                }
            }

            function changeGrid(grid){
                style.value = grid;
            }
            function updateTotals(totals){
                post_count.value = totals;
            }

            return {
                style,
                tag,
                post_count,
                user_count,
                users,
                changeGrid,
                updateTotals,
                loadingUser,
                postLoader: computed(() => store.state.PostsStore.postLoader),
            }
        },
        components:{
            PostsLoader,
            GroupTags,
            SkeletonQuickUser
        },
    }
</script>
